@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');
body{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 15px;
}
#logo {
    width: 160px;
    padding: 0px 0px;
}
#basic-navbar-nav {
    margin-top: auto;
    font-size: 16px;
}
.nav-link {
    color: white !important;
    text-transform: uppercase;
    margin-left: 15px;
}
.nav-link:hover {
    color: black !important;
    margin-left: 15px;
}
.nav-link:focus {
    color: black !important;
    margin-left: 15px;
    outline: none;
}
.navbar-toggler {
    margin-top: auto;
    margin-bottom: 10px;
    color: white;
}
.dropdown-menu{
    background-color: #e30613;
    border: none;
}
.dropdown-item{
    background-color: #e30613;
    color: white;
    border: none;
}
.dropdown-item:hover{
    background-color: #e30613;
    color: black;
    border: none;
}
.dropdown-item:focus{
    background-color: #e30613;
    color: black;
    border: none;
}

.dropdown-submenu {
    position: relative;
  }
  
  .dropdown-submenu a::after {
    transform: rotate(-90deg);
    position: absolute;
    right: 6px;
    top: .8em;
  }
  
  .dropdown-submenu .dropdown-menu {
    top: 0;
    left: 100%;
    margin-left: .1rem;
    margin-right: .1rem;
  }
  .card{
    box-shadow: 0px 0px 5px 3px#eeeeee;
    border-radius: 3px;
    border:none;
  }
  .sidebar-card {
    border: none;
    text-align: center;
    box-shadow: 0px 0px 5px 3px#eeeeee;
    border-radius: 3px;
    margin-bottom: 25px;
  }
  .sidebar-card:last-child {
    margin-bottom: 25px;
  }
a{
    outline: none;
    color: #e30613;
}
a:hover{
    outline: none;
    color: #e30613;
    text-decoration: none;
}
.sidebar-game-countdown-wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 26px;
    background: #e30613;
    color:white;
    border-radius: 2px;
    font-weight: 500;
}
.single-post-link {
    color: black;
}
.single-post-link:hover {
    color: black;
}
.post-card-title{
    color: #e30613;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}
.card-text {
    font-size: 15px;
}
p:last-child {
    margin-bottom: 0px !important;
}
.single-post-title{
    color: #e30613;
    font-size: 24px;
    margin: 20px 0px 5px 0px;
}
.single-post-date{
    margin: 0px 0px 0px 0px;
    font-size: 14px;
    font-weight: 700;
}
.team-hero-text-wrapper{
    text-align: center;
    font-size: 14px;
    padding: 20px 20px;
}
.team-hero-title{
    color: #e30613;
    font-size: 26px;
    font-weight: 600;
}
#team-image{
    max-width: 100%;
    height: auto;
}
.team-hero-title{
    margin: 0px 0px 10px 0px;
}

.player-content{
    text-align: center;
    padding-bottom: 10px;
}
.player-title{
    margin-top: 10px;
    font-size: 18px;
}
.player-role{
    font-size: 13px;
}
.single-player-name{
    font-size: 28px;
    font-weight: 600;
    color: #e30613;
    margin-top: 3px;
    margin-bottom: 0px;
}
.single-player-position{
    font-size: 28px;
    font-weight: 600;
    color: #e30613;
    margin-left: auto;
}
.single-player-membersince{
    font-size: 12px;
    margin-top: -3px;
}
.single-player-info-items{
    margin-top: 20px;
}
.single-player-info-item{
    font-size: 14px;
    margin-bottom: 10px;
}
.single-player-details{
    margin-top: 20px;
    font-size: 14px;
}
.social-icon{
    font-size: 16px;
}
.single-player-social{
    margin: 10px 0px 0px 0px;
}
.player-sm-profile-icon {
    padding: 5px;
    margin-right: 10px;
    font-size: 28px;
    color: white;
    background-color: #e30613;
    border-radius: 3px;
}
.btn-primary{
    background-color: #e30613;
    border-color: #e30613;
    box-shadow: none;
}
.btn-primary:hover{
    background-color: #bd0511;
    border-color: #e30613;
    outline:none;
}
.btn-primary:active{
    background-color: #bd0511;
    border-color: #e30613;
    outline:none;
}
.btn-primary:focus{
    background-color: #bd0511;
    border-color: #e30613;
    outline:none;
}
.btn-primary:visited{
    background-color: #e30613 !important;
    border-color: #e30613 !important;
    outline:none;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: 
#fff;
background-color:
#e30613;
border-color:
    #e30613;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.management-view-info{
    padding: 40px 0px 0px 0px;
}
.management-view-name{
    font-size: 20px;
}
.management-view-role{
    font-size: 12px;
}
.main-title{
    font-size: 30px;
    color: #e30613;
    margin: 0px 0px 10px 0px;
    font-weight: 600;
}
.trainings-item{
    margin-bottom: 3px;
    font-size: 14px;
}
.trainings-map{
    margin: 15px 0px 0px 0px;
}
.btn-back{
    font-size: 12px;
    padding: 3px 10px;
}
.singleton-content h1{
    color: #e30613;
    font-size: 30px;
    margin: 0px 0px 10px 0px;
    font-weight: 600;
}
.singleton-content h2{
    color: #e30613;
}
.singleton-content h3{
    color: #e30613;
}
.singleton-content h4{
    color: #e30613;
}
.singleton-content h5{
    color: #e30613;
}
.anfahrt-text-wrapper {
    text-align: center;
    padding: 20px 20px;
    font-size: 13px;
}
.anfahrt-text-wrapper h1 {
    color: #e30613;
    font-size: 30px;
    margin: 0px 0px 10px 0px;
    font-weight: 600;
}
.match-card-body {
    font-size: 14px;
}
.match-number {
    color: #505050;
}
.match-date {
    font-weight: 600;
}
.match-time {
    font-weight: 600;
}
.match-result {
    color: #e30613;
    font-weight: 600;
}
.match-result-item{
    font-size: 13px;
    color: #e30613;
    font-weight: 600;
}
.match-card-row{
    margin-bottom: 20px;
}
.download-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.download-image{
    width: 100%;
}
.download-info-item{
    font-size:12px;
}
.default-input {
    display: block;
    width: 100%;
}
.default-label {
    font-size: 16px;
    margin-bottom: 5px;
}
.form-group{
}
input {
    font-size: 14px;
    border: 1px solid #eeeeee;
    padding: 8px 10px;
    border-radius: 3px;
}
input:focus {
    font-size: 14px;
    border: 1px solid #eeeeee;
}
input:focus {

}
button, input {
    margin-right: 7px;
}
.form-check{
    padding-left: 0px;
    font-size: 14px;
}
.form-check .checkbox-inline {
    margin-right: 20px;
}
textarea {
    font-size: 14px;
    padding-top: 5px;
    padding-left: 5px;
}
.swal2-confirm{
    background-color: #e30613 !important;
    border-color: #e30613 !important;
}
.btn-back{
}
.footer {
    background-color: #e30613;
    color: white;
    font-size: 14px;
}
.footer-row{
    padding: 60px 0px 40px 0px;
}
.copyright-footer{
    background-color: #c20512;
    color: white;
    font-size: 13.5px;
    text-align: center;
    padding: 10px 0px;
}
.footer h4 {
    font-size: 28px;
    font-weight: 600;
}
.footer-item {
    color: white;
}
.footer-item:hover {
    color: white;
}
.footer-item:focus {
    color: white;
}
.footer-item-wrapper{
    margin-bottom: 11px;
}
.footer-item-wrapper:last-child{
    margin-bottom: 0px;
}
.footer-item-title{
    margin-bottom: 20px;
}
.footer-item-title2{
    margin-bottom: 20px;
}
.footer-second-col {
    margin: 10px 0px 40px 0px;
}
.footer-info-col{
    margin-bottom: 0px;
}
.countdown{
    background-color: #e30613;
    color: white;
    font-size: 26px;
    margin: 15px 0px 0px 0px;
}
.post-card-excerpt{
    font-size: 15.5px;
}
.single-post-content h1 {
    font-size: 26px;
}
.single-post-content h2 {
    font-size: 24px;
}
.single-post-content h3 {
    font-size: 22px;
}
.single-post-content h4 {
    font-size: 20px;
}
.single-post-content h5 {
    font-size: 18px;
}
/* sm */
@media (min-width: 576px) {
    .card-text {
        font-size: 14px;
    }
    .post-card-title{
        color: #e30613;
        font-size: 15px;
    }
    .team-hero-text-wrapper{
        font-size: 14px;
        padding: 20px 20px;
    }
    .single-player-name{
        font-size: 22px;
        font-weight: 600;
        color: #e30613;
        margin-top: 3px;
        margin-bottom: 0px;
    }
    .single-player-position{
        font-size: 22px;
        font-weight: 600;
        color: #e30613;
        margin-left: auto;
    }
    .match-card-body {
        font-size: 12px;
    }
    .footer-second-col {
        margin: 0px 0px 30px 0px;
    }
    .footer-item-title{
        margin-bottom: 32px;
    }
    .post-card-excerpt{
        font-size: 14px;
    }
    .footer-item-title2{
        margin-bottom: 0px;
    }
}
/* md */
@media (min-width: 768px) {
    .card-text {
        font-size: 16px;
    }
    .post-card-title{
        color: #e30613;
        font-size: 17px;
    }
    .team-hero-text-wrapper{
        font-size: 14px;
        padding: 20px 20px;
    }
    .single-player-name{
        font-size: 28px;
        font-weight: 600;
        color: #e30613;
        margin-top: 3px;
        margin-bottom: 0px;
    }
    .single-player-position{
        font-size: 28px;
        font-weight: 600;
        color: #e30613;
        margin-left: auto;
    }
    .trainings-item{
        margin-bottom: 3px;
        font-size: 16px;
    }
    .match-card-body {
        font-size: 14px;
    }
}
/* lg */
@media (min-width: 992px) {
    .post-card-title{
        color: #e30613;
        font-size: 14px;
    }
    .card-text {
        font-size: 16px;
    }
    .team-hero-text-wrapper{
        font-size: 13px;
        padding: 0px 20px;
    }
    .anfahrt-text-wrapper {
        text-align: center;
        padding: 30px 20px;
    }
    .footer-second-col {
        margin: 0px 0px 0px 0px;
    }
}
/* xl */
@media (min-width: 1200px) {
    .post-card-title{
        color: #e30613;
        font-size: 17px;
    }
    .team-hero-text-wrapper{
        font-size: 14px;
        padding: 0px 20px;
    }
}

